import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import { Link } from 'gatsby';
import Iframe from 'react-iframe';
import { Title, Testonimal, Twitter } from "../../components/Globals";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';
import "./awesome-slider-override.css";
import { FaAngleDoubleDown } from "react-icons/fa";
const MultisoftConsultingPage = () => {

    const ref = React.createRef();

    const [services] = React.useState([
        {
          id: 1,
          title: 'Salesforce Partner',
          desc: 'Trusted Salesforce Partner with 115+ certified Salesforce professionals and a global presence.',
          image: 'mulesoft-partners.png',
          alt:'mulesfot partners companies India USA',
          imagetitle:'Trusted Salesforce Partner With Global Presence ',
          url: {
           
          }
        },
        {
          id: 2,
          title: 'Industry & Domain Expertise',
          desc: 'We understand industry and domain specific challenges and infuse strategic guidance & best practices in our solutions. ',
          image: 'mulesoft-consulting-services.png',
          alt:'muleSoft consulting services',
          imagetitle:'Deep Industry & Domain Expertise',
          url: {
          }
        },
        {
          id: 3,
          title: 'Trusted Partnership',
          desc: 'Our agile partnership can help you seamlessly navigate intricate business transformations. ',
          image: 'mulesoft-sap-integration-services.png',
          alt:'mulesoft sap oracle microsoft dynamics integration services',
          imagetitle:'Partner With Us',
          url: {
          }
        },
        {
          id: 4,
          title: 'Scalability',
          desc: 'We offer scalable and cross-functional solutions being part of an integral part of a multi-capabilty group.',
          image: 'mulesoft-companies-mumbai-india-usa.png',
          alt:'salesforce mulesoft integration services',
          imagetitle:'Scalable Solutions',
          url: {
          }
        },
     
      ]);

    const arrowClick = () =>
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
  return (
        <Layout>
         
            <div className="multisoft-consulting-background flex flex-col">
            <Helmet>
                <title>Mulesoft Consulting & Integration Services | Cymetrix Software | India, USA</title>
                <meta name="description" content="Cymetrix Mulesoft Consulting Partner provides salesforce consulting services ranging from planning, implementation, and customization."/>
                <meta name="keywords" content="cymetrix,mulesoft consulting, mulesoft implementation, mulesoft partners, mulesfot integration services, salesforce mulesoft, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
                <link rel="canonical" href="https://www.cymetrixsoft.com/services/mulesoft-consulting/" />
            </Helmet>
            <h1 className=" xl:text-4xl xl:m-56  md:m-16 sm:m-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
                text-white text-uppercase text-center">
                    MULESOFT CONSULTING PARTNER TO INTEGRATE YOUR PROCESSES AND APPLICATIONS
                </h1>
                <button class="focus:outline-none " >
         <FaAngleDoubleDown className=" w-32 h-20 py-5 text-white" onClick={arrowClick}/>
        </button>
            </div>
            <div className="max-w-6xl mx-auto justify-center" >
                <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-10" ref={ref}>
                    <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-lef" >
                        <h1 className="text-3xl text-bold" style={{color: '#1e407b'}}>MULESOFT SERVICES</h1>
                    </div>
                    <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
                        <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                            <img
                                style={{width: 300}}
                                src={require('./../../images/services/mulesofticon.png')}
                                alt="Cymetric Software - Mulesoft Consulting  Service Logo"
                                title="Mulesoft Consulting Service Logo"
                                />
                        </div>
                    </div>
                </div>
                <p>Our team of experienced Mulesoft consultants are willing to assist you develop a strategy and implement a goal based solution in mulesoft consulting services.</p><br/>
                <p>Opportunities to transform and disrupt are created through agility and scale by connecting the right data with the right people without losing visibility and control. The Cymetrix Digital Integration Center of Excellence is focused on enabling its customers with agility and innovation driven by connectivity and APIs.</p>
                <br/>
            </div>
            <div className="product-background w-full flex flex-col items-center" title="">
                <div className="max-w-6xl mx-4 my-16">
                    <h1 className="text-white text-3xl text-left sma-xl font-thin">Mulesoft Consulting Services</h1>
                    <div class="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                    <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Mulesoft Consulting & API strategy</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Craft a MuleSoft API strategy and roadmap to fast-track business outcomes through an API-led connectivity approach.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Architecture definition and delivery</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Define and deliver architecture seamlessly by merging product expertise, accelerators, and best practices, ensuring a smooth transition to MuleSoft adoption and implementation.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Mulesoft Managed Services</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Opt for MuleSoft Managed Services providing 24/7 support and maintenance customized to your business goals, ensuring seamless operations and ongoing success.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Mulesoft Implementation & Development</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Collaborate with us to design a tailored plan and optimize API usage for maximum benefits from your MuleSoft tools.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Mulesoft Integration</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">We design scalable architectures that support multi-platform integrations and service-oriented architecture, adhering to the API-led connectivity design pattern.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Mulesoft Connector Development</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">With our experience of developing, testing, and certifying connectors paired with our integration expertise for Mulesoft, we can construct a component that meets your organization and customer’s integration requirements.</div>
                            </div>
                        </div>
                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">MuleSoft Composer</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Automate integrations to Salesforce or any other system you like and instantly launch your digital projects.</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center">
                        <div className="bg-transparent hover:border-blue-500 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center text-white">
                            <Link target="_blank"
                                to="/contact-us">Contact Cymetrix</Link>
                        </div>
                    </div>
                </div>
        </div>


        <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">
              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
              Drive connected experiences with Salesforce and Mulesoft  														
              </h1><br />
              <p className="font-hairline text-justify">Leverage the seamless synergy of Salesforce and MuleSoft with Cymetrix—your trusted partner in crafting customized Salesforce solutions. As  <Link to="/services/salesforce-consulting/" class="underline text-blue-700"> Salesforce Partners</Link> and experts, we bring a unique blend of experience to drive connected experiences, utilizing the power of MuleSoft to build flexible architectures, unlock cross-system data, and create differentiated, connected solutions at an accelerated pace.				
              </p><br/>
              </div>

              <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
              <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/salesforce-mulesoft-integration-services.png')}
                      alt="Salesforce Mulesoft Integration Services"
                      title="Accelerate digital transformation with Salesforce and Mulesoft"
                      />
                </div>
              </div>
          </div>
        </div>

        <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">

          <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
              <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/salesforce-ERP-sap-integration-company.png')}
                      alt="Salesforce ERP Integration Services"
                      title="Integrate CRM and ERP to win through connected journeys"
                      />
                </div>
              </div>

              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
              Streamline Your ERP Integration with Salesforce Using MuleSoft  														
              </h1><br />
              <p className="font-hairline text-justify">Cymetrix offers MuleSoft integration solutions for ERP systems like SAP, Microsoft Dynamics, and Oracle ERP among others. Our customized integration solutions tailored to your specific business needs and requirements, ensuring a unified view of customer data and streamlined business processes.. Don't let data silos and manual processes hinder your business growth. 
              </p><br/>
              <p className="font-hairline text-justify"><Link to="/contact-us" class="underline text-blue-700"> Partner With Us</Link>
              </p><br/>
              </div>
              

          </div>
        </div>


        <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">
              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
              Power Your Growth Journey with On-Demand Salesforce Talent 														
              </h1><br />
              <p className="font-hairline text-justify">Whether you need to rapidly expand your operations or tackle complex projects, our on-demand model ensures that the right Salesforce resources are available when you need them.				
              </p><br/>
              <p className="font-hairline text-justify"><Link to="/services/staff-augmentation-services" class="underline text-blue-700">Staff Augmentation Services</Link>
              </p><br/>
              </div>

              <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
              <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/salesforce-integration-partner-services.png')}
                      alt="Salesforce Mulesoft Integration Services"
                      title="Salesforce MuleSoft"
                      />
                </div>

              </div>
          </div>
        </div>

            
            <div className="w-full" style={{backgroundColor: '#1e407b'}}>
                <div className="max-w-6xl mx-auto">
                    <h1 className="sma:px-3 text-3xl text-extrabold text-white py-5"><Link to="/about/case-studies" class="underline">CASE STUDY</Link></h1>
                    <p className="text-white sma:px-3">Client is a leader in the employee benefits marketplace, simplifying benefits and risk management for local, regional, and Fortune 1000 companies.</p>
                    <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                    
                    <div className="sma:px-3 md:w-1/2 lg:w-1/3 px-6 flex">
                        <div className="sma:mt-3 flex flex-col mx-auto max-w-xs items-center mt-12">
                            <div className="px-3 py-4 text-white">
                                <h4 class="text-2xl"><b>CHALLENGE</b></h4>
                                <ul class="list-disc mt-5">
                                    <li>The Client leveraged Cymetrix to build a new benefits platform from ground-up across web and mobile. Their current application lacked the scalability and flexibility to meet growing business needs. As part of this, the client needed to integrate data from several internal and external sources.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="sma:px-3 md:w-1/2 lg:w-1/3 px-6 flex">
                        <div className="sma:mt-3 flex flex-col mx-auto max-w-xs items-center mt-12">
                            <div className="px-3 py-4 text-white">
                                <h4 class="text-2xl"><b>SOLUTION</b></h4>
                                <ul class="list-disc mt-5 ">

                                    <li>Cymetrix helped the client in selecting and implementing the MuleSoft platform to address the data integration issues. The solution integrated internal systems and applications that accommodated batch processing of large datasets, aggregating and enriching data from all sources. It presented the data in a seamless and secure manner via APIs to the web and mobile presentation layers. The security gateway and identity management solution provided secure access, while the APIs were governed by the Mule API manager and implemented as a cloud, on-premise hybrid solution
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="sma:px-3 md:w-1/2 lg:w-1/3 px-6 flex ">
                        <div className="sma:mt-3 flex flex-col mx-auto max-w-xs items-center mt-12">
                            <div className="px-3 py-4 text-white">
                                <h4 class="text-2xl"><b>RESULTS</b></h4>
                                <ul class="list-disc mt-5">
                                    <li>Delivered a robust, reliable, and scalable enterprise SOA integration platform based on the Mulesoft API Integration Suite enabling faster and efficient data integration.
                                    </li>
                                    <li>
                                    Enhanced end-customer experience with a refreshed omni-channel benefits platform.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
         

        <div className="service-background w-full flex flex-col items-center ">
          <div className="max-w-6xl mx-4 sma:mb-4">
              <Title title="Why Choose Cymetrix for MuleSoft Consulting Services" classValue={'text-white sma:text-xl text-4xl'} />
              <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                {
                  services.map(service => {
                      return <div className="mt-4 md:w-1/2 lg:w-1/4" key={service.id}>
                          <div className="bg-white p-8 h-full border-b-4 border-pink-500 rounded-lg hover:border-hover flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
                              <div className="text-indigo-700 w-32 flex items-center justify-center h-32 p-2 rounded-full">
                              <img src={require(`../../images/services/${service.image}`)} title={service.imagetitle} alt={service.alt} className="w-24 h-24"/>
                              </div>
                              <h1 className="mt-5 text-center">{service.title}</h1>
                              <p className="mt-5 text-sm text-gray-600 text-center">{service.desc}</p>
                              <Link to={service.url.url} className="mt-5 text-hover text-lg text-center">{service.url.title}</Link>
                          </div>
                      </div>
                  })
                }
              </div>
          </div>
        </div>


            <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">From Our Blogs</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-partners-license-types.png')} alt="Salesforce partners for licencing | Cymterix Software" title="Salesforce license types" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/11/02/understanding-salesforce-license-types-for-optimization-and-cost-reduction/" >Understanding Salesforce License Types For Optimization and Cost Reduction</Link></h1>
                            <p className="text-lg">Read about the core attributes of Salesforce.com licensing agreements.</p>
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-einstien-gpt-ai.png')} alt="Salesforce Einstien GPT | Cymetrix Software" title="Why Salesforce generative AI is the next big thing?" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/11/07/salesforce-einstein-gpt-why-generative-ai-is-the-next-big-thing/">Salesforce Einstein GPT: Why Generative AI is the next big thing?</Link></h1>
                            <p className="text-lg">Read about how Salesforce Einstien is revolutionizing the way CRM works.</p>
                        </div>
                    </div>
                </div>
            </div>



            <div className="flex flex-col mx-auto items-center rounded-lg pb-10">
                    <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1"/>
                    <div className="px-6 py-4 bg-white  text-gray-700">
                        <h4 class="text-lg" style={{color: '#1e407b'}}><b>FAQs:</b></h4>
                        <br/>
                        <h4 class="text-lg">How can I improve business agility? How I increase development speed and onboard new systems?</h4>
                        <p>Cymetrix offers its strategic<Link to="/services/salesforce-consulting/" class="underline text-blue-600" >  Salesforce consulting partner services </Link> enabling business and digital transformation for its clients. It leverages its in-house state of the art Digital Integration Strategic Framework to help customers meet their objectives</p>

                        <br/>
                        <h4 class="text-lg">How can I tap the information trapped in legacy systems? How can I streamline geographically distributed information and systems?</h4>
                        <p> With its expertise and experience, Certified Salesforce Consultants and salesforce implementation services - Cymetrix Software offers wide range of delivery services which can be tailormade to suit to our customer needs.</p>
                        <br/>
                        <h4 class="text-lg">In which locations does Cymetrix provide Mulesoft consulting and implementation services?</h4>
                        <p> We can provide near-shore consultation and offshore delivery powered by our network of offices in USA, UK, Europe, Japan and India.</p>

                    </div>
                </div>
        </Layout>
    );
}

export default MultisoftConsultingPage;